export const images = {
    blog_place: require('../assets/images/blog_place.png'),
    imgP: require('../assets/images/img5.png'),
    imgB: require('../assets/images/img7.png'),
    imgH: require('../assets/images/homeMade.png'),
    food: require('../assets/images/placeholder.png'),
    dateIcon: require('../assets/images/calendar.png'),
    img1: require('../assets/images/img1.1.png'),
    logoImg: require('../assets/images/logo2.png'),
    logoFfk: require('../assets/images/logo3.png'),
    offline: require('../assets/images/offline1.jpg'),
    homeImg: require('../assets/images/store.svg'),
    delivery: require('../assets/images/delivery.svg'),
    coupon: require('../assets/images/coupon.png'),
    deliveryBlue: require('../assets/images/delivery-blue.svg'),
    storeBlue: require('../assets/images/store-blue.svg'),
    userIcon: require('../assets/images/user.png'),
    cartIcon: require('../assets/images/cart.png'),
    googleIcon: require('../assets/images/google.png'),
    VisaIcon: require('../assets/images/vsc.png'),
    MasterIcon: require('../assets/images/msc.png'),
    ebtIcon: require('../assets/images/credit-card.png'),
    ebtH: require('../assets/images/ebt.svg'),
    AmericanIcon: require('../assets/images/aec.png'),
    JcbIcon: require('../assets/images/jcb.png'),
    EbtIcon: require('../assets/images/ebt.svg'),
    DDIcon: require('../assets/images/ddc.png'),
    checkedIcon: require('../assets/images/checked.png'),
    checkIcon: require('../assets/images/check.svg'),
    unCheckedIcon: require('../assets/images/unchecked.png'),
    roadIcon: require('../assets/images/road-1.png'),
    playStore: require('../assets/images/btn-play.png'),
    appStore: require('../assets/images/btn-ios.png'),
    fbIcon: require('../assets/images/fb.png'),
    Img3: require('/src/assets/images/new-img3.png'),
    Img5: require('/src/assets/images/new-img5.png'),
    homeImg4: require('/src/assets/images/home-img4.png'),
    FoodImg: require('/src/assets/images/new-img2.png'),
    SliderImg: require('/src/assets/images/slider-img.png'),
    NewImg3: require('/src/assets/images/img3.png'),
    avgImg: require('/src/assets/images/avg.png'),
    NewImg8: require('/src/assets/images/new-img8.png'),
    Img2: require('/src/assets/images/img2.png'),
    NewImg7: require('/src/assets/images/new-img7.png'),
    success: require('/src/assets/images/tick.png'),
    successStar: require('/src/assets/images/success-star.svg'),
    not_found: require('/src/assets/images/not-found.png'),
    address: require('/src/assets/images/address.png'),
    card: require('/src/assets/images/card.png'),
    order_food: require('/src/assets/images/to-do-list.png'),
    list: require('/src/assets/images/list.png'),
    whoImg: require('/src/assets/images/who.jpg'),
    about: require('/src/assets/images/about.png'),
    specials: require('/src/assets/images/specials.png'),
    shopMenu: require('/src/assets/images/shop-menu.png'),
    help: require('/src/assets/images/question.png'),
    info: require('/src/assets/images/info.png'),
    chef3: require('/src/assets/images/chef-hat3.png'),
    lists: require('/src/assets/images/lists.png'),
    enter: require('/src/assets/images/enter.png'),
    catering: require('/src/assets/images/food-tray.png'),
    account: require('/src/assets/images/user.png'),
    orderList: require('/src/assets/images/shopping-bag.png'),
    logout: require('/src/assets/images/power-off.png'),
    articleImg: require('/src/assets/images/article-img.png'),
    mailBox: require('/src/assets/images/mail-box.png'),
    siGoogle: require('/src/assets/images/google-plus.svg'),
    sifacebook: require('/src/assets/images/facebook.svg'),
    siApple: require('/src/assets/images/apple.svg'),


};
