import React from "react";
import {NavLink, withRouter} from 'react-router-dom';
import 'rc-slider/assets/index.css';
import {images} from '../../constant';
import {Range} from "rc-slider";
import {CapitalizeFirstLetter, EventEmitter} from "../../helper";
import API from "../../api";
import {Cookies, withCookies} from "react-cookie";
import {instanceOf} from "prop-types";
import {Modal} from "react-bootstrap";

const api = new API();

class Sidebar extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            switch_to_farmers : false,
            active_class : "",
            hide_categories: true,
            redirect_url: "/",
            auth_user: '',
            token: this.props.cookies.get('token'),
            restaurants: [],
            category_slug: "",
            start_price: 0,
            end_price: 1000,
            value: [0, 1000],
            is_prime: false,
            is_bulk: false,
            made_locally: false,
            ebt_enabled: false,
            lowToHigh: false,
            highToLow: false,
            is_on_sell: false,
            categories_slug: "",
            categories_name: "",
            keyword: this.props.match.params.search_key,
            ebt_payments : 0,
            all_products : true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.updateRange = this.updateRange.bind(this);
        this.refreshCategory = this.refreshCategory.bind(this);
        this.sortingWithPrice = this.sortingWithPrice.bind(this);
        this.handleToggleClose = this.handleToggleClose.bind(this);
        this.handleRedirection = this.handleRedirection.bind(this);
    }

    logOut = () => {
        const {cookies} = this.props;
        const domain = process.env.RAZZLE_WEB_DOMAIN;
        const formattedDomain = domain?.startsWith('.') ? domain.substring(1) : domain;
        const token = '';
        cookies.set('token', token);
        cookies.set('cart_id', token);
        cookies.set('list_unique_id', token);
        cookies.set('list_type_id', token);
        cookies.set('auth_user', token);
        cookies.set('userLatLng', token);
        cookies.set("date",token);
        cookies.set("time",token);
        this.setState({token});
        window.location.href = '/login';
    };

    handleAuthRedirection(path) {
        if (path === "catering"){
            this.setState({
                categories_slug: "catering",
                categories_name: "Catering",
            })
        }
        EventEmitter.dispatch('sidebarToggleClose');
        EventEmitter.dispatch('changeKeywordResetFilter');
        document.getElementById("body-mob").classList.remove("fixed-position");
        document.getElementById("body-mob").classList.remove("fixed-search");
        this.props.history.push(path);
    }

    componentDidMount() {
        api.getSingleRestaurant(1)
            .then((restaurant) => {
                this.setState({
                    ebt_payments : restaurant.data.response?.ebt_payments
                });
            });

        const filters = localStorage.getItem("categories");
        const categories = JSON.parse(filters);
        if (categories == undefined){
            api.getCategories({keyword: "all", category: null}).then((success)=>{
                this.setState({categories : success.data.response?.categories },()=>{
                    localStorage.setItem('categories', JSON.stringify(success.data.response?.categories))
                })
            });
        }

        if (this.state.auth_user !== this.props.cookies.get('auth_user')) {
            this.setState({auth_user: this.props.cookies.get('auth_user')});
        }

        EventEmitter.subscribe('changeCategoryResetFilter', () => {
            this.setState({
                category_slug: "",
                categories_slug: "",
                categories_name: "",
            }, () => {
                this.setFilterValues();
            })
        });

        EventEmitter.subscribe('changeRangeResetFilter', () => {
            this.setState({
                value: [0, 1000],
            }, () => {
                this.setFilterValues();
            })
        });

        EventEmitter.subscribe('changePrimeResetFilter', () => {
            this.setState({
                is_prime: false,
            }, () => {
                this.setFilterValues();
            })
        });

        EventEmitter.subscribe('changeBulkResetFilter', () => {
            this.setState({
                is_bulk: false,
            }, () => {
                this.setFilterValues();
            })
        });

        EventEmitter.subscribe('changeLocalResetFilter', () => {
            this.setState({
                made_locally: false,
            }, () => {
                this.setFilterValues();
            })
        });

        EventEmitter.subscribe('changeEBTResetFilter', () => {
            this.setState({
                ebt_enabled: false,
            }, () => {
                this.setFilterValues();
            })
        });

        EventEmitter.subscribe('changeAllResetFilter', () => {
            this.setState({
                category_slug: "",
                categories_slug: "",
                categories_name: "",
                start_price: 0,
                end_price: 1000,
                value: [0, 1000],
                is_prime: false,
                is_bulk: false,
                made_locally: false,
                ebt_enabled : false,
                lowToHigh: false,
                highToLow: false,
                is_on_sell: false,
            }, () => {
                // this.setFilterValues();
            })
        });


        EventEmitter.subscribe('toggleFromFilter', () => {
            this.setState({hide_categories: false})
        });

        EventEmitter.subscribe('toggleFromHeader', () => {
            this.setState({hide_categories: true})
        });

        EventEmitter.subscribe('changeKeywordFilter', () => {
            const keyword = localStorage.getItem("keyword");
            const keyword_data = JSON.parse(keyword);
            this.setState({
                all_products : !keyword_data?.key ? true : false ,
            })
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.auth_user !== this.props.cookies.get('auth_user')) {
            this.setState({auth_user: this.props.cookies.get('auth_user')});
        }
        if (this.state.token !== this.props.cookies.get('token')) {
            this.setState({token: this.props.cookies.get('token')});
        }
    }

    handleToggleClose() {
        EventEmitter.dispatch('sidebarToggleClose');
        document.getElementById("body-mob").classList.remove("fixed-position");
        document.getElementById("body-mob").classList.remove("fixed-search");
    }

    handleRedirection() {
        EventEmitter.dispatch('sidebarToggleClose');
        EventEmitter.dispatch('changeKeywordResetFilter');
        EventEmitter.dispatch('changeCategoryResetFilter');
        document.getElementById("body-mob").classList.remove("fixed-position");
        document.getElementById("body-mob").classList.remove("fixed-search");
        this.props.history.push('/');
    }

    refreshCategory(slug, name) {

        this.setState({categories_slug: slug, categories_name: name,all_products : false}, () => {

            EventEmitter.dispatch('sidebarToggleClose');

            localStorage.setItem('filter', JSON.stringify({
                    range: this.state.value,
                    categories_slug: this.state.categories_slug,
                    categories_name: this.state.categories_name,
                    filter: {
                        is_prime: this.state.is_prime,
                        is_bulk: this.state.is_bulk,
                        made_locally: this.state.made_locally,
                        ebt_enabled : this.state.ebt_enabled,
                        is_on_sell: this.state.is_on_sell
                    },
                    highToLow: this.state.highToLow,
                    lowToHigh: this.state.lowToHigh,
                }
            ));

            EventEmitter.dispatch('changeKeywordResetFilter');
            if (this.props.location.pathname.startsWith("/category")) {
                EventEmitter.dispatch('changeFilter');
            }
            document.getElementById("body-mob").classList.remove('fixed-position');
            this.props.history.push({
                pathname:"/category/"+slug,
            });
        });
    }


    manageLocalStorage(){

        localStorage.setItem('filter', JSON.stringify({
                range: this.state.value,
                categories_slug: this.state.categories_slug ? this.state.categories_slug : this.props.categories_slug,
                categories_name: this.state.categories_name ? this.state.categories_name : this.props.categories_name,
                filter: {
                    is_prime: this.state.is_prime,
                    is_bulk: this.state.is_bulk,
                    made_locally: this.state.made_locally,
                    ebt_enabled : this.state.ebt_enabled,
                    is_on_sell: this.state.is_on_sell
                },
                highToLow: this.state.highToLow,
                lowToHigh: this.state.lowToHigh,
            }
        ));
    }

    setFilterValues() {
        this.manageLocalStorage();
        EventEmitter.dispatch('changeFilter');
        if (this.props.location.pathname.startsWith("/category")) {
            this.props.history.push({pathname: this.props.location.pathname.toString()});
        } else {
            this.props.history.push({pathname: "/"});
        }
    }

    handleChange(event) {
        const state = event.target.name;
        if (state === "is_prime") {
            this.setState({is_prime: event.target.type === "checkbox" ? event.target.checked : event.target.value}, () => {
                this.setFilterValues();
            });
        }
        if (state === "is_bulk") {
            this.setState({is_bulk: event.target.type === "checkbox" ? event.target.checked : event.target.value}, () => {
                this.setFilterValues();
            });
        }
        if (state === "made_locally") {
            this.setState({made_locally: event.target.type === "checkbox" ? event.target.checked : event.target.value}, () => {
                this.setFilterValues();
            });
        }
        if (state === "is_on_sell") {
            this.setState({is_on_sell: event.target.type === "checkbox" ? event.target.checked : event.target.value}, () => {
                this.setFilterValues();
            });
        }

        if (state === "ebt_enabled") {
            this.setState({ebt_enabled: event.target.type === "checkbox" ? event.target.checked : event.target.value}, () => {
                this.setFilterValues();
            });
        }
    }

    sortingWithPrice(e) {

        if (e.target.value === "0") {
            this.setState({
                lowToHigh: false,
                highToLow: false
            }, () => {
                this.setFilterValues();
            });
        }

        if (e.target.value === "1") {
            this.setState({
                lowToHigh: true,
                highToLow: false
            }, () => {
                this.setFilterValues();
            });
        }

        if (e.target.value === "2") {
            this.setState({
                lowToHigh: false,
                highToLow: true
            }, () => {
                this.setFilterValues();
            });
        }
    }

    updateRange(value) {  ///RANGE FILTER FUNCTION
        this.setState({value: value}, () => {
            this.setFilterValues();
        });
    };

    render() {

        var filters = undefined ;
        if (typeof window !== 'undefined') {
            filters = localStorage.getItem("categories");
        }
        const categories = filters != undefined ? JSON.parse(filters) : this.state.categories;
        const categorySlug = this.state.categories_slug ? this.state.categories_slug : this.props.categories_slug;

        let search_keyword = "";
        if (typeof window !== 'undefined') {
            const keyword = localStorage.getItem("keyword");
            const keyword_data = JSON.parse(keyword);
            search_keyword = keyword_data?.key || "";
        }


        return (

            <>
                <React.Fragment>
                    <div className="col-12 col-md-2 col-lg-2 col-xl-3 column1">
                        <div className={this.props?.showFilter ? this.props?.showFilter : "sidebar bg-white"}>

                            {this.state.hide_categories === true &&
                            <>
                                <div className="user-menu d-block d-md-none">
                                    <ul className="navbar-nav menu-details ml-auto border-bottom mb-2 pb-2 pl-0">
                                        {this.state.token ? (
                                            <li className="title d-inline-block mb-0">
                                                <span className="d-block mb-1">Hi,&nbsp;{this.state.auth_user !== "" ? CapitalizeFirstLetter(this.state.auth_user) : ""}</span>
                                                <ul className="d-flex flex-column w-100">
                                                    <li>
                                                        <a onClick={() => this.handleAuthRedirection('/user/personal-information')}>
                                                            <img
                                                                src={
                                                                    images.account
                                                                }
                                                                alt="Not Found"
                                                            />
                                                            My account
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={() => this.handleAuthRedirection('/user/grocery-pickup-orders')}>
                                                            <img
                                                                src={
                                                                    images.orderList
                                                                }
                                                                alt="Not Found"
                                                            />
                                                            My Orders
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={() => this.logOut()}>
                                                            <img
                                                                src={
                                                                    images.logout
                                                                }
                                                                alt="Not Found"
                                                            />
                                                            Log Out
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        ) : (
                                            <li>

                                                <NavLink
                                                    exact
                                                    to={`/login?ref=${this.state.redirect_url === "/signUp" || this.state.redirect_url === "/login" ? "" : this.state.redirect_url.replace('/', '')}`}
                                                    onClick={() => this.setState({
                                                        toggle_class: "collapse navbar-collapse",
                                                        toggle_menu_class: ""
                                                    }, () => {
                                                        document.getElementById("body-mob").classList.remove("fixed-position");
                                                        document.getElementById("body-mob").classList.remove("fixed-search");
                                                        EventEmitter.dispatch('changeKeywordResetFilter');
                                                        this.handleToggleClose();
                                                    })}
                                                >
                                                    <img
                                                        src={
                                                            images.enter
                                                        }
                                                        alt="Not Found"
                                                    />
                                                    <b>Login</b>
                                                </NavLink>
                                                <span>or</span>
                                                <NavLink
                                                    exact
                                                    to={`/signUp?ref=${this.state.redirect_url === "/login" || this.state.redirect_url === "/signUp" ? "" : this.state.redirect_url.replace('/', '')}`}
                                                    onClick={() => this.setState({
                                                        toggle_class: "collapse navbar-collapse",
                                                        toggle_menu_class: ""
                                                    }, () => {
                                                        document.getElementById("body-mob").classList.remove("fixed-position");
                                                        document.getElementById("body-mob").classList.remove("fixed-search");
                                                        EventEmitter.dispatch('changeKeywordResetFilter');
                                                        this.handleToggleClose();
                                                    })}
                                                >
                                                    <b>Sign up</b>
                                                </NavLink>
                                            </li>
                                        )}
                                    </ul>

                                    <ul className="navbar-nav menu-details border-bottom mb-3 pb-2">
                                        <li className="nav-item">
                                            <a className={this.state.active_class !== 3 && this.props.location.pathname.toString() === "/" ? "nav-link active" : "nav-link"}
                                               href="javascript:void(0)"
                                               onClick={() => {
                                                   this.setState({active_class: 1}, () => {
                                                       this.handleRedirection()
                                                   })
                                               }}
                                            >
                                                <img
                                                    src={
                                                        images.shopMenu
                                                    }
                                                    alt="Not Found"
                                                />
                                                Shop
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a className={this.state.active_class !== 3 && this.props.location.pathname.toString() === "/catering" ? "nav-link active" : "nav-link"}
                                               href="javascript:void(0)"
                                               onClick={() => {
                                                   this.setState({active_class: 2}, () => {
                                                       this.handleAuthRedirection('/catering')
                                                   })
                                               }}
                                            >
                                                <img
                                                    src={
                                                        images.catering
                                                    }
                                                    alt="Not Found"
                                                />
                                                Catering
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a className={this.state.active_class === 3 ? "font-weight-normal nav-link active" : "nav-link"}
                                               href="javascript:void(0)" onClick={() => {
                                                this.setState({switch_to_farmers: true, active_class: 3}, () => {
                                                    this.handleToggleClose();
                                                })
                                            }}>
                                                <img
                                                    src={
                                                        images.chef3
                                                    }
                                                    alt="Not Found"
                                                />
                                                Fresh Kitchen
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink
                                                className={this.state.active_class !== 3 && this.props.location.pathname.toString() === "/locations-contact" ? "nav-link active" : "nav-link"}
                                                onClick={() => {
                                                    this.setState({active_class: 4}, () => {
                                                        EventEmitter.dispatch('changeKeywordResetFilter');
                                                        this.handleToggleClose();
                                                    })
                                                }}
                                                exact
                                                to="/locations-contact"
                                            >

                                                <img
                                                    src={
                                                        images.info
                                                    }
                                                    alt="Not Found"
                                                />
                                                Location & Hours
                                            </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink
                                                exact
                                                to="/user/item-list"
                                                className={this.state.active_class !== 3 && this.props.location.pathname.toString() === "/user/item-list" ? "nav-link active" : "nav-link"}
                                                onClick={() => {
                                                    this.setState({active_class: 4}, () => {
                                                        EventEmitter.dispatch('changeKeywordResetFilter');
                                                        this.handleToggleClose();
                                                    })
                                                }}
                                                // onClick={() => this.setState({
                                                //     active_class: 5,
                                                //     toggle_class: "collapse navbar-collapse",
                                                //     toggle_menu_class: ""
                                                // }, () => {
                                                //     document.getElementById("body-mob").classList.remove("fixed-position");
                                                //     document.getElementById("body-mob").classList.remove("fixed-search");
                                                //     EventEmitter.dispatch('changeKeywordResetFilter');
                                                // })}
                                            >
                                                <img
                                                    src={
                                                        images.lists
                                                    }
                                                    alt="Not Found"
                                                />
                                                Lists
                                            </NavLink>
                                        </li>

                                        {/*<li className="nav-item d-none">*/}
                                        {/*    <NavLink*/}
                                        {/*        exact*/}
                                        {/*        className={this.state.active_class === 6 ? "nav-link active" : "nav-link"}*/}
                                        {/*        to="/locations-contact"*/}
                                        {/*        onClick={() => this.setState({*/}
                                        {/*            active_class: 6,*/}
                                        {/*            toggle_class: "collapse navbar-collapse",*/}
                                        {/*            toggle_menu_class: ""*/}
                                        {/*        }, () => {*/}
                                        {/*            document.getElementById("body-mob").classList.remove("fixed-position");*/}
                                        {/*            document.getElementById("body-mob").classList.remove("fixed-search");*/}
                                        {/*            EventEmitter.dispatch('changeKeywordResetFilter');*/}
                                        {/*        })}*/}
                                        {/*    >*/}
                                        {/*        <img*/}
                                        {/*            src={*/}
                                        {/*                images.help*/}
                                        {/*            }*/}
                                        {/*            alt="Not Found"*/}
                                        {/*        />*/}
                                        {/*        <b>Help</b>*/}
                                        {/*    </NavLink>*/}
                                        {/*</li>*/}
                                    </ul>
                                </div>


                                <div className="d-block">
                                    <h2 className="mb-md-3 mb-2 shop-title">Shop from</h2>
                                    <ul>
                                        <li>
                                            <a href="javascript:void(0);" className={ ((categorySlug === undefined || categorySlug === null || categorySlug === "") && this.state.all_products && !search_keyword )? "active" : ""}
                                               onClick={() => {
                                                   this.setState({categories_name: "", categories_slug: "",all_products : true}, () => {
                                                       this.manageLocalStorage();
                                                       EventEmitter.dispatch('changeKeywordResetFilter');
                                                       EventEmitter.dispatch('changeAllResetFilter');
                                                       EventEmitter.dispatch('changeResetFilter');
                                                       this.props.history.push({pathname: "/"});
                                                   })
                                               }}>
                                                All
                                            </a>
                                        </li>

                                        { categories && categories.length ? categories.map(category => (
                                            <li key={category.id}>
                                                <a href="javascript:void(0);"
                                                   className={category.slug === categorySlug ? "active" : ""}
                                                   onClick={() => {
                                                       this.refreshCategory(category.slug, category?.category_name)
                                                   }}>
                                                    {category?.category_name}
                                                </a>
                                            </li>
                                        )) : ""}
                                        <li>
                                            <a href="javascript:void(0);"
                                               className={categorySlug === "catering" ? "active" : ""}
                                               onClick={() => {
                                                   this.handleAuthRedirection("/catering")
                                               }}>
                                                Catering
                                            </a>
                                        </li>
                                    </ul>
                                </div>


                                <div className="col-md-12 filter pb-1 pt-2">
                                    <ul>
                                        <li className="d-block"
                                            onClick={() => {
                                                this.refreshCategory("best-sellers", "Best Sellers")
                                            }}>
                                            <a href="javascript:void(0)"
                                               className={"best-sellers" === categorySlug ? "active" : ""}>
                                                Best Sellers
                                            </a>
                                        </li>
                                        <li className="d-block"
                                            onClick={() => {
                                                this.refreshCategory("weekly-sale", "Weekly Sale")
                                            }}>
                                            <a href="javascript:void(0)"
                                               className={"weekly-sale" === categorySlug ? "active" : ""}>
                                                Weekly Sale
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                            </>
                            }

                            {(this.props.location.pathname.toString() === "/" || this.props.location.pathname.startsWith('/category')) &&
                            <>
                                <div className="col-md-12 filter pb-1 d-block d-md-none">
                                    <h2>Sort by</h2>
                                    <h6>
                                        <label className="control control--checkbox">
                                            <b>Best match</b>
                                            <input type="radio" name="price_filter" id="3"
                                                   checked={this.state.highToLow === false && this.state.lowToHigh === false}
                                                   value={0} onClick={(e) => this.sortingWithPrice(e)}/>
                                            <div className="control__indicator"></div>
                                        </label>
                                    </h6>
                                    <h6>
                                        <label className="control control--checkbox">
                                            <b>Price Low to High</b>
                                            <input type="radio" name="price_filter" id="4"
                                                   checked={this.state.highToLow === false && this.state.lowToHigh === true}
                                                   value={1} onClick={(e) => this.sortingWithPrice(e)}/>
                                            <div className="control__indicator"></div>
                                        </label>
                                    </h6>
                                    <h6>
                                        <label className="control control--checkbox">
                                            <b>Price High to Low</b>
                                            <input type="radio" name="price_filter" id="5" value={2}
                                                   checked={this.state.highToLow === true && this.state.lowToHigh === false}
                                                   onClick={(e) => this.sortingWithPrice(e)}/>
                                            <div className="control__indicator"></div>
                                        </label>
                                    </h6>
                                </div>

                                <div className="col-md-12 filter ">
                                    <h2>Price Range</h2>
                                    <div className="range-slider">
                                        <p>${this.state.value[0]}</p>
                                        <p>${this.state.value[1]}</p>
                                    </div>
                                    <Range
                                        min={this.state.start_price}
                                        max={this.state.end_price}
                                        onChange={this.updateRange}
                                        defaultValue={[this.state.start_price, this.state.end_price]}
                                        value={this.state.value}
                                    />
                                </div>


                                <div className="col-md-12 filter">
                                    <h2> Ways to shop </h2>
                                    <h6>
                                        <label className="control control--checkbox">
                                            <img
                                                src={
                                                    images.imgP
                                                }
                                                alt="Not Found"
                                            />
                                            <b> Prime Items</b>
                                            <input type="checkbox" name="is_prime" id="3"
                                                   checked={this.state.is_prime} value={this.state.is_prime}
                                                   onClick={this.handleChange}/>
                                            <div className="control__indicator"></div>
                                        </label>
                                    </h6>
                                    <h6>
                                        <label className="control control--checkbox">
                                            <img
                                                src={
                                                    images.imgB
                                                }
                                                alt="Not Found"
                                            />
                                            <b> Bulk Items</b>
                                            <input type="checkbox" name="is_bulk" id="4"
                                                   checked={this.state.is_bulk} value={this.state.is_bulk}
                                                   onClick={this.handleChange}/>
                                            <div className="control__indicator"></div>
                                        </label>
                                    </h6>
                                    <h6>
                                        <label className="control control--checkbox">
                                            <img
                                                src={
                                                    images.imgH
                                                }
                                                alt="Not Found"
                                            />
                                            <b> Local Items</b>
                                            <input type="checkbox" name="made_locally" id="5"
                                                   checked={this.state.made_locally} value={this.state.made_locally}
                                                   onClick={this.handleChange}/>
                                            <div className="control__indicator"></div>
                                        </label>
                                    </h6>

                                    {(this.state.ebt_payments == 1) &&
                                        <h6>
                                            <label className="control control--checkbox">
                                                <img
                                                    src={
                                                        images.ebtH
                                                    }
                                                    alt="Not Found"
                                                />
                                                <b>SNAP EBT Eligible</b>
                                                <input type="checkbox" name="ebt_enabled" id="5"
                                                       checked={this.state.ebt_enabled} value={this.state.ebt_enabled}
                                                       onClick={this.handleChange}/>
                                                <div className="control__indicator"></div>
                                            </label>
                                        </h6>
                                    }
                                </div>
                            </>
                            }

                        </div>
                    </div>

                    <Modal
                        dialogClassName="modal-dialog-centered"
                        onHide={() => {
                            this.setState({
                                switch_to_farmers: false
                            })
                        }} show={this.state.switch_to_farmers} className="storeModal">
                        <Modal.Body>
                            <div className="modal-body">
                                <div className="col-12 text-center p-0">
                                    <span className="">
                                          <img width="200px"
                                               src={images.logoFfk}
                                          />
                                    </span>
                                    <h6 className="card-text w-100 mt-3 mb-2">You’ll be directed to our dedicated
                                        Farmer’s Fresh Kitchen site, where you can conveniently order hot food to
                                        go.</h6>
                                    <h6 className="card-text w-100 mb-3">Sure you want to proceed?</h6>
                                </div>
                                <hr/>
                                <div className="text-center">
                                    <button onClick={() => {
                                        this.setState({switch_to_farmers: false}, () => {
                                            window.open('https://farmersfreshkitchen.com/', '_blank');
                                        })
                                    }}
                                            className="btn btn-s proceed-button mb-3 mb-md-0"
                                            style={{
                                                transition: 'all .3s ease-in-out'
                                            }}>Yes, Proceed
                                    </button>
                                    <button onClick={() => this.setState({switch_to_farmers: false})}
                                            className="btn btn-s cart-button">Cancel
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                </React.Fragment>
            </>
        )
    }
}

export default withRouter(withCookies(Sidebar));
