import React from 'react';

import {asyncComponent} from '@jaredpalmer/after';

export default [

    //Authentication
    {
        path: '/',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Filter/searchItem'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/similar-products',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Common/productGrid'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/catering',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Common/CateringGrid'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/catering/:product_id',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Common/SingleCatering'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/catering-checkout',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Checkout/cateCheckout'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/login',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/Login'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/signUp',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/Signup'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/forgot-password',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/Forgot'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/reset-password/:email',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/Reset'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },


   //Authenticated Routes
    {
        path: '/user/personal-information',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/PersonalInformation'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/address-details',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/AddressDetails'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/add-address',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/AddAddres'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/security-setting',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/Setting'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/profile',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/EditProfile'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/communication-preferences',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/Communication'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/payment-details',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/PaymentDetails'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/add-payment-cards',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/AddPaymentCard'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/grocery-pickup-orders',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/GroceryPickupOrders'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/grocery-delivery-orders',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/GroceryDeliveryOrders'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/catering-pickup-orders',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/CateringPickupOrders'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/catering-delivery-orders',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/CateringDeliveryOrders'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/hot-item-orders',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/HotItemOrders'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/delivery-orders',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/GroceryDeliveryOrders'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/digital-coupons',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/DigitalCoupons'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/item-list',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/ItemList'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/tax-exemption',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/TaxExemption'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/order-list',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/OrderList'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },




    //Products & checkout
    {
        path: '/shop/:product_id',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Common/singleProduct'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/search/:search_key',
        exact: false,
        component: asyncComponent({
            loader: () => import('./component/Filter/searchItem'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/product-category/:category',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Common/ProductCategory'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/on-sale-now',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Common/onSaleProduct'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/best-seller-products',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Common/BestSellerProduct'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/category/:category_slug',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Filter/CategoryFilter'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/cart',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Checkout/cart'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/user/lists',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Auth/AfterAuth/ItemList'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/checkout',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Checkout/checkout'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/order/:order_id',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Payment/successOrder'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },

    //static pages routes

    {
        path: '/about',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/about_us'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/locations-contact',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/find_store'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },

    {
        path: '/frequent-asked-question',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/faq'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },

    {
        path: '/about/employment',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/career'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/houston-deer-processing-what-to-look-for',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/houston-deer-processing-what-to-look-for'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/blogs',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/articles'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/blog/:blog_slug',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/singleBlog'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/blog-tag-archives',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/tag_archives'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/blog-category-archives',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/category_archives'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/facilities',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/facilities'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/privacy-policy',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/privacy'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/terms-and-condition',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/term_condition'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    // {
    //     path: '/support',
    //     exact: true,
    //     component: asyncComponent({
    //         loader: () => import('./component/Static/support'), // required
    //         Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    //     }),
    // },
    {
        path: '/refund-policy',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/refund_policy'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/wholesale',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/wholesale'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/food-truck-wholesale',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/food_truck_wholesale'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/restaurants-wholesale',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/restaurants_wholesale'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/about/our-meat',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/our_meat'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/sitemap',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/sitemap'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/about/specialty-services',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/specialty_services'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/our-selection/specials-monthly-salepaper',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/specials'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/testimonial',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/testimonial'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/text-cullen',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/Promotion'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/text-mesa',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/Promotion'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '/promotion',
        exact: true,
        component: asyncComponent({
            loader: () => import('./component/Static/Promotion'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },
    {
        path: '**',
        component: asyncComponent({
            loader: () => import('./component/Static/NotFound'), // required
            Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
        }),
    },

];
