import React, {Component} from 'react';
import Popover from '@material-ui/core/Popover';
import {capitalizeWords} from "../../helper";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import API from "../../api";
import {setTimingInfo} from "../../actions/timing";
import {Cookies, withCookies} from "react-cookie";
import {instanceOf} from "prop-types";
import * as moment from "moment";

const api = new API();

class ShopHoursClass extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            isPopoverOpen: false,
            anchorEl: null,
            timings : [
                {day: 'monday', from: '07:00:00', to: '19:00:00'},
                {day: 'tuesday', from: '07:00:00', to: '19:00:00'},
                {day: 'wednesday', from: '07:00:00', to: '19:00:00'},
                {day: 'thursday', from: '07:00:00', to: '19:00:00'},
                {day: 'friday', from: '07:00:00', to: '19:00:00'},
                {day: 'saturday', from: '07:00:00', to: '19:00:00'},
                {day: 'sunday', from: '08:00:00', to: '16:00:00'},
            ],
            restaurant_id: this.props.cookies.get('selected_store') !== undefined ? this.props.cookies.get('selected_store') : 2,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', (e) => {
            this.setState({anchorEl: null})
        });

        /*if (this.state.timings.length === 0 || this.props.getTimeInfo.length === 0) {
            api.getRestaurantAvailability(this.props.cookies.get('selected_store') || 2).then(success => {
                this.setState({timings: success.data?.response?.grocery_timings || [] },()=>{
                    this.props.setTimeInfo(success.data.response);
                });
            });
        }*/
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {shopCloseId = '', shopOpenId = ''} = this.props;

        /*if (prevProps.getTimeInfo !== this.props.getTimeInfo){
            this.setState({
                timings : this.props.getTimeInfo
            })
        }*/

        /*if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.restaurant_id){
               api.getRestaurantAvailability(this.props.cookies.get('selected_store') || 2).then(success => {
                   this.props.setTimeInfo(success.data.response);
               });
        }*/

        if ((prevProps.showDropdown !== this.props.showDropdown) && this.props.showDropdown) {
            const isShopOpen = document.getElementById(shopOpenId);
            const isShopClosed = document.getElementById(shopCloseId);
            if (!!isShopOpen) {
                isShopOpen.click();
            }
            if (!!isShopClosed) {
                isShopClosed.click();
            }
        }
    }

    parseTime = (date, timeString) => {
        const dateString = date.toISOString().split('T')[0];
        const parsedDate = new Date(`${dateString}T${timeString}`);
        return parsedDate.toString() === 'Invalid Date' ? null : parsedDate;
    };

    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    render() {

        const data = this.state.timings;
        if (!(data && data.length && data.length > 0)) {
            return (
                <section>
                    <div>
                        <span className="font-10">Loading...</span>
                    </div>
                </section>
            )
        }

        const currentDay = new Date().toLocaleDateString("en-US", { weekday: "long" }).toLowerCase();
        const currentTime = new Date().toLocaleTimeString("en-US", { hour12: false });

        const currentDayData = data.find((entry) => entry.day.toLowerCase() === currentDay);
        if (!currentDayData) {
            return <p>Shop data not available for {currentDay}.</p>;
        }

        const nextDay = new Date();
        nextDay.setDate(nextDay.getDate() + 1);
        const nextDayName = nextDay.toLocaleDateString("en-US", { weekday: "short" });
        const nextDayFullName = nextDay.toLocaleDateString("en-US", { weekday: "long" }).toLowerCase();
        const nextDayData = data.find((entry) => entry.day.toLowerCase() === nextDayFullName);
        if (!nextDayData) {
            return <p>Shop data not available for {nextDayName}.</p>;
        }

        const closingTime = this.parseTime(new Date(), currentDayData.to);
        const openingTime = this.parseTime(new Date(), nextDayData.from);

        if (!closingTime || !openingTime) {
            return <p>Invalid date format in shop data for {currentDay}.</p>;
        }

        const formattedClosingTime = closingTime.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });

        const formattedOpeningTime = openingTime.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });

        const isShopOpen = currentTime >= currentDayData.from && currentTime < currentDayData.to;

        const open = Boolean(this.state.anchorEl);
        const id = open ? "simple-popover" : undefined;
        const {isFooter = false, shopCloseId = '', shopOpenId = ''} = this.props;
        return (
            <>
                <div>
                    {isShopOpen ? (
                        <p id={shopOpenId} className="font-10 d-flex w-max align-items-center cursor"
                           onClick={this.handleClick} aria-describedby={id}>
                            <span
                                className={`font-weight-medium text-open `}> Open </span>
                            <span className="mx-1 px-1 font-weight-medium">|</span>
                            <span className={`text-timing ${isFooter ? 'footer-bg' : ''}`}>
                            Closes {formattedClosingTime.toLowerCase()}
                            </span>
                            {this.state.anchorEl ?
                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                    <path stroke="#AAA" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-miterlimit="10" stroke-width="1.5" d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm4.06 11.79c-.15.15-.34.22-.53.22s-.38-.07-.53-.22l-3-3-3 3c-.29.29-.77.29-1.06 0a.754.754 0 010-1.06l3.53-3.53c.29-.29.77-.29 1.06 0l3.53 3.53c.29.3.29.77 0 1.06z">
                                    </path>
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                    <path stroke="#AAA" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-miterlimit="10" stroke-width="1.5" d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm4.06 9.27l-3.53 3.53c-.15.15-.34.22-.53.22s-.38-.07-.53-.22l-3.53-3.53a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l3 3 3-3c.29-.29.77-.29 1.06 0 .29.29.29.76 0 1.06z">
                                    </path>
                                </svg>
                            }
                        </p>
                    ) : (
                        <p id={shopCloseId} className="font-10 d-flex w-max align-items-center cursor"
                           onClick={this.handleClick} aria-describedby={id}>
                            <span className="font-weight-medium text-danger"> Closed </span>
                            <span className="mx-1 px-1 font-weight-medium">|</span>
                            Open {formattedOpeningTime.toLowerCase()} {nextDayName}
                            {this.state.anchorEl ?
                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                    <path stroke="#AAA" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-miterlimit="10" stroke-width="1.5" d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm4.06 11.79c-.15.15-.34.22-.53.22s-.38-.07-.53-.22l-3-3-3 3c-.29.29-.77.29-1.06 0a.754.754 0 010-1.06l3.53-3.53c.29-.29.77-.29 1.06 0l3.53 3.53c.29.3.29.77 0 1.06z">
                                    </path>
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                    <path stroke="#AAA" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-miterlimit="10" stroke-width="1.5" d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm4.06 9.27l-3.53 3.53c-.15.15-.34.22-.53.22s-.38-.07-.53-.22l-3.53-3.53a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l3 3 3-3c.29-.29.77-.29 1.06 0 .29.29.29.76 0 1.06z">
                                    </path>
                                </svg>
                            }
                        </p>
                    )}
                </div>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    disableScrollLock={true}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    className="mt-4"
                >
                    <ul className="text-right w-100 timing-main">
                        {data.length > 0 && data.map((value, index)=>{

                            const formattedStartTime = this.parseTime(new Date(), value.from).toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                            });

                            const formattedEndTime = this.parseTime(new Date(), value.to).toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                            });

                            const isToday = (moment().format('dddd')).toLowerCase() === value.day;
                           return (
                               <>
                                   <li className="m-0 text-left date-item d-flex align-items-center justify-content-between">
                                       <span className={`font-weight-medium ${isToday ? 'text-blue' : ''}`}>
                                       {capitalizeWords(value.day)}
                                       </span>
                                       <span className={` ${isToday ? 'font-weight-medium' : ''}`}>{formattedStartTime}-{formattedEndTime}</span></li>
                               </>
                           )
                        })}
                    </ul>
                </Popover>
            </>
        );
    }
}

/*const mapStateToProps = (state, ownProps) => {
    return {
        getTimeInfo: state.TimingReducer?.info?.data?.grocery_timings || []
    };
};*/

/*const mapDispatchToProps = (dispatch) => {
    return {
        setTimeInfo: (data) => dispatch(setTimingInfo({ type:'GET_TIMING_INFO', data : data })),
    }
};*/

export default withRouter(withCookies(ShopHoursClass));
